<template>
  <div style="position: relative; width: 100%; height: 100%; min-height: 100%;">
    <iframe
      v-if="!isDemoUser && isEnableToShowIFrame"
      :src="iframeData.frameUrl"
      frameborder="0"
      allowfullscreen
      style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
      height="100%"
      width="100%"
    />
    <base-dialog
      ref="sparePartToolInfoDialog"
      :title="$t('langkey.modal-sparepartstool-header')"
      @close="$router.push($i18nRoute({
        name: 'tyre'
      }))"
    >
      <a href="https://tyresnparts.com/de/register-full">
        <h3
          v-html="$t('langkey.modal-sparepartstool-message1')"
        />
      </a>
      <img
        :src="require('@/assets/images/spare-parts-tool-modal.png')"
        style="width: 100%;"
      >
      <h3
        v-html="$t('langkey.modal-sparepartstool-message2')"
      />
    </base-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      isEnableToShowIFrame: false,
    };
  },
  computed: {
    ...mapState({
      iframeData: (state) => state.carService.iframeData,
      userInformation: (state) => state.user.userInformation,
    }),
    isDemoUser() {
      return this.userInformation.demo;
    },
  },
  mounted() {
    if (this.isDemoUser) {
      this.$refs.sparePartToolInfoDialog.show();
      return;
    }

    this.$store.dispatch('dialog/setLoadingDialog', {
      status: true,
    });
    this.$store.dispatch('carService/setCarServiceIframeData')
      .then(() => {
        this.isEnableToShowIFrame = true;
      })
      .catch((err) => {
        if (err && (err.response.status === 403 || err.response.status === 401)) {
          this.$refs.sparePartToolInfoDialog.show();
        }
      })
      .finally(() => {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      });
  },
};
</script>

<style>

</style>